import React, {useState} from "react";
import {render} from "react-dom";
import {Form} from 'react-final-form'
import CheckBox from "./checkbox";
import FormField from "./form-field";
import Input from "./input";
import {
  composeValidators,
  emailValidator,
  formatEmail,
  formatPhone,
  formatRusSymbols,
  minSymbols,
  onlyRussianSymbolsAndNumberValidator,
  onlyRussianSymbolsValidator,
  phoneValidator,
  required, sleep,
} from "../utils";
import FormError from "./form-error";
import Textarea from "./textarea";
import '../../css/form/contact-form.scss';
import axios from "axios";
import Modal from "./modal";

const ContactForm = () => {

  const [resData, setResData] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const sendData = async (data, form) => {

    const formData = new FormData();

    formData.append('name', data.name)
    formData.append('phone', data.phone)
    formData.append('email', data.email)
    formData.append('message', data.message)

    return axios({

      method: 'post',
      headers: {'Content-type': 'multipart/form-data'},
      url: '/public/send.php',
      data: formData
    })
      // post(`http://a-rgo_site/public/send.php`, values)
      .then(function (data) {
        // console.log(JSON.parse(data));
        if (form && form.restart) {
          form.restart()
        }
        console.log(`status: ${data.data.status}, message: ${data.data.message}`);
        setShowSuccess(true)
      })
      .catch(function (data) {
        // console.log(JSON.parse(data));
        console.log(data);
        setShowSuccess(false)
        setResData(`При отправке письма произошла ошибка - ${data.message}`)
      })
  }

  const onSubmit = async (values, form) => {
    await sendData(values, form)

  }

  return (

    <div className={'container form'}>
      {showSuccess && <Modal
        title={'Спасибо!'}
        onBackgroundClick={() => setShowSuccess(false)}
        onCloseClick={() => setShowSuccess(false)}
      >
        <p>Ваше сообщение было отправлено.
          Скоро мы свяжемся с вами.
        </p>
      </Modal>
      }
      <div key={'info'} className={'form-info'}>
        <div key={'title'} id={'form-info-title'} className={'form-info-title'}>Контакты</div>
        <div key={'contacts'} className={'form-info-contacts'}>
          <a style={{fontWeight: 600}} href={'tel:+79219357679'} key={'phone'} className={'form-info-contacts-email'}>
            Евгений Бакаушин<br/>
            Коммерческий директор<br/>
            8(921)935-76-79<br/>
          </a>
          <a href={'mailto:office@a-rgo.org'} key={'email'} className={'form-info-contacts-phone'}>office@a-rgo.org</a>
          <a href={'tel:+78123274505'} key={'phone'} className={'form-info-contacts-phone'}>
            8(812) 327-45-05
          </a>
        </div>
        <div key={'text'} className={'form-info-text'}>Напишите нам сообщение и&nbsp;мы&nbsp;свяжемся{'\n'} с&nbsp;вами
          в&nbsp;ближайшее
          время
        </div>
        {/*<div key={'required'} className={'form-info-required'}>Все поля обязательны к заполнению</div>*/}
        {/*{resData && <div key={'message'} className={'form-info-message'}>{resData}</div>}*/}
      </div>
      {/*<Form*/}
      {/*  key={1}*/}
      {/*  onSubmit={onSubmit}*/}
      {/*  render={({handleSubmit, submitting}) => (*/}
      {/*    <div key={'table'} className={'col-xs-12 col-md-12 col-xxl-11 col-xxxl-9 form-table'}>*/}
      {/*      <form key={2} onSubmit={handleSubmit} id={'contact-form'}>*/}
      {/*        <div className={'row m-0'}>*/}
      {/*          <div className={'col -xs-12 col-md-12 col-lg-4 p-0 position-relative'}>*/}
      {/*            <FormField*/}
      {/*              id={'name'}*/}
      {/*              key={'name'}*/}
      {/*              name={'name'}*/}
      {/*              readOnly={submitting}*/}
      {/*              component={<Input/>}*/}
      {/*              type={'text'}*/}
      {/*              placeholder={'Имя'}*/}
      {/*              format={formatRusSymbols}*/}
      {/*              validate={composeValidators(required, onlyRussianSymbolsValidator, minSymbols(2))}*/}
      {/*            />*/}
      {/*            <FormError key={'nameError'} name={'name'}/>*/}
      {/*          </div>*/}
      {/*          <div className={'col-xs-12 col-md-6 col-lg-4 p-0 position-relative'}>*/}
      {/*            <FormField*/}
      {/*              id={'phone'}*/}
      {/*              key={'phone'}*/}
      {/*              readOnly={submitting}*/}
      {/*              name={'phone'}*/}
      {/*              component={<Input/>}*/}
      {/*              type={'text'}*/}
      {/*              placeholder={'Телефон'}*/}
      {/*              format={formatPhone}*/}
      {/*              validate={composeValidators(required, phoneValidator)}*/}
      {/*            />*/}
      {/*            <FormError key={'phoneError'} name={'phone'}/>*/}
      {/*          </div>*/}
      {/*          <div className={'col-xs-12 col-md-6 col-lg-4 p-0 position-relative'}>*/}
      {/*            <FormField*/}
      {/*              id={'email'}*/}
      {/*              key={'email'}*/}
      {/*              readOnly={submitting}*/}
      {/*              name={'email'}*/}
      {/*              component={<Input/>}*/}
      {/*              type={'email'}*/}
      {/*              placeholder={'Email'}*/}
      {/*              format={formatEmail}*/}
      {/*              validate={composeValidators(required, emailValidator)}*/}
      {/*            />*/}
      {/*            <FormError key={'emailError'} name={'email'}/>*/}
      {/*          </div>*/}
      {/*          <div className={'col-xs-12 col-md-12 p-0 position-relative'}>*/}
      {/*            <FormField*/}
      {/*              id={'message'}*/}
      {/*              key={'message'}*/}
      {/*              readOnly={submitting}*/}
      {/*              name={'message'}*/}
      {/*              component={<Textarea minHeight={100}/>}*/}
      {/*              type={'textarea'}*/}
      {/*              placeholder={'Сообщение'}*/}
      {/*              format={formatRusSymbols}*/}
      {/*              validate={composeValidators(required, onlyRussianSymbolsAndNumberValidator, minSymbols(2))}*/}
      {/*            />*/}
      {/*            <FormError key={'messageError'} name={'message'}/>*/}
      {/*          </div>*/}
      {/*          <div className="col">*/}
      {/*            <div className="row form-table-agreement">*/}
      {/*              <div*/}
      {/*                className={'col-xs-12 col-md-6 col-lg-8 p-0 d-flex position-relative align-items-md-center'}>*/}
      {/*                <FormField*/}
      {/*                  id={'agreement'}*/}
      {/*                  readOnly={submitting}*/}
      {/*                  key={'agreement'}*/}
      {/*                  name={'agreement'}*/}
      {/*                  component={<CheckBox/>}*/}
      {/*                  type={'checkbox'}*/}
      {/*                  placeholder={'Сообщение'}*/}
      {/*                  desc={<div>Я согласен на <a key={'link'}*/}
      {/*                                              href={'/public/documents/Политика_по_обработке_и_защите_персональных_данных_ООО_А_РГО.pdf'}*/}
      {/*                                              download={''}>обработку персональных данных</a></div>}*/}
      {/*                  validate={required}*/}
      {/*                />*/}
      {/*                <FormError key={'agreementError'} name={'agreement'}/>*/}
      {/*              </div>*/}
      {/*              <div className={'col-xs-12 col-md-6 col-lg-4 p-0'}>*/}
      {/*                {*/}
      {/*                  submitting ?*/}
      {/*                    <div className={'form-table-submit'}>Загрузка...</div> :*/}
      {/*                    <button key={'submit'} className={'form-table-submit'}>Отправить заявку</button>*/}
      {/*                }*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}

      {/*        </div>*/}
      {/*      </form>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*/>*/}
    </div>
  )
}

render(
  <ContactForm/>
  , document.getElementById(`contact-form-app`));
